.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px #eeeeee;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  /*align-items: center;*/
}

.bottom {
  flex: 0;
  border-top: solid 1px #eeeeee;
}

.adm-tab-bar-item-title {
  font-size: 1em;
}

.adm-tab-bar-wrap {
  background-color: lightyellow;
}

.adm-list-item-content {
  font-size: 0.9em;
}
